<template>
    <div class="project-block" :id="`block-${block.id}`">
        <div class="select-project">
            <div class="input-box" v-if="!selectedProject">
                <div class="icon-left"><icon-projects /></div>
                <multiselect ref="vms"
                v-model="selectedProject"
                :options="projects"
                :allow-empty="false"
                :show-labels="false"
                :multiple="false" 
                :close-on-select="true"
                track-by="id"
                label="name"
                @select="selectOptionAndClose"
                >
                    <template slot="placeholder" slot-scope="props">
                        <span class="text">{{$t('projects.choose_project')}}</span>
                    </template>
                    <template slot="option" slot-scope="props">{{ props.option.name }}</template>
                    <template slot="singleLabel" slot-scope="{ option }">{{ option.name }}</template>
                    <template slot="noResult">{{ $t('general.no_result') }}</template>
                    <template slot="noOptions">{{ $t('general.empty_list') }}</template>
                </multiselect>
            </div>
            <template v-else>
                <router-link :to="{ name: 'projects-edit', params: { slug: selectedProject.slug } }" >
                    <div class="icon"><icon-project-redirect /></div>
                    <div class="name" v-html="selectedProject.name"></div>
                </router-link>
            </template>
            <div class="delete" @click="deleteBlock"><icon-delete /></div>
        </div>
    </div>

</template>

<script>
	import IconDelete from '@/components/Icons/Trash';
	import IconProjects from '@/components/Icons/Projects';
	import IconProjectRedirect from '@/components/Icons/ProjectRedirect';

    export default {
        inheritAttrs: false,
        components: {
            IconDelete,
            IconProjects,
            IconProjectRedirect
        },
        props: {
            block: Object
        },
        watch: {
            block: {
                handler(value) {
                    if(value.project_id) {
                        this.selectedProject = this.projects.find(el => el.id == value.project_id) ? this.projects.find(el => el.id == value.project_id) : '';
                    }
                },
            }
        },
        data() {
            return {
                projects: this.$store.state.blocks.projects,
                selectedProject: ''
            }
        },
        mounted() {
            this.projects[this.projects.findIndex(el => el.slug == this.$route.params.slug)].$isDisabled = true;

            if(this.block.project_id) {
                this.selectedProject = this.projects.find(el => el.id == this.block.project_id) ? this.projects.find(el => el.id == this.block.project_id) : '';
            }
        },
        methods: {
            selectOptionAndClose(selectedOption) {
                this.selectedProject = selectedOption;

                axios.post(`/projects/${this.$route.params.slug}/sync`, { type: 'attach', linked_project_id: this.selectedProject.id })

                this.$store.dispatch('blocks/editBlock', { ...this.block, slug: this.selectedProject.slug, project_id: this.selectedProject.id, html: this.selectedProject.name });
                this.$emit('updateProject');
            },
            deleteBlock(e) {
                if(this.selectedProject) {
                    axios.post(`/projects/${this.$route.params.slug}/sync`, { type: 'detach', linked_project_id: this.selectedProject.id })
                }
                
                this.$emit('deleteBlock', e);
            }
        }
    }
</script>